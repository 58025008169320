function initMap() {
  var e = new google.maps.LatLng(36.244160, 136.235356),
      o = new google.maps.Map(document.getElementById("gmap"), {
          zoom: 12,
          center: e,
          scrollwheel: !1
      }),
      s = (new google.maps.Marker({
          position: e,
          map: o
      }), [{
          stylers: [{
              saturation: -100
          }]
      }]),
      l = new google.maps.StyledMapType(s);
  // o.mapTypes.set("GrayScaleMap", l), o.setMapTypeId("GrayScaleMap")
}
$(function(){
  // navbar
  $('#navbars').click(function(){
    $(this).removeClass('show');
  });

  //スムーススクロール
  $('a[href^="#"]').smoothScroll({offset: -50});
  // home
  if ($('body').hasClass('home')) {
    ///windowが開いたときに黒からコンテンツがフェードインする
    // $('.navbar').delay(500).fadeIn(1500);
    $('.cover-opening').delay(500).fadeOut(1500);
    // 幅広時 video表示
    var getWindowMovieHeight = function() {
      // ここでブラウザの縦横のサイズを取得します。
      var windowSizeHeight = $(window).outerHeight();
      var windowSizeWidth = $(window).outerWidth();

      // メディアの縦横比に合わせて数値は変更して下さい。(メディアのサイズが width < heightの場合で書いています。逆の場合は演算子を逆にしてください。)
      var windowMovieSizeWidth = windowSizeHeight * 1.50943396;
      var windowMovieSizeHeight = windowSizeWidth / 1.50943396;
      var windowMovieSizeWidthLeftMargin = (windowMovieSizeWidth - windowSizeWidth) / 2;
      if (windowMovieSizeHeight < windowSizeHeight) {
        // 横幅のほうが大きくなってしまう場合にだけ反応するようにしています。
        $("#video").css({left: -windowMovieSizeWidthLeftMargin});
      }
    };
    // 以下画面の可変にも対応できるように。
    $(window).imagesLoaded(function(){
      getWindowMovieHeight();
    });
    $(window).on('resize', function(){
      getWindowMovieHeight();
    });
    // 幅狭時、GIF画像表示
    $('.cover-slide').backstretch("/uploads/home.gif");
    // $('#feature').tubular({
    //   videoId: '4K8FJ5H6qjc'  // CkkXBueVc3o kcyeu_1teZ4 埋め込みたいYoutubeのIDを記述
    // });
    $('#customer-testimonials').click(function(){
      window.location=$(this).find("a").attr("href");
      return false;
    });
    // NEWS背景変化
    function newsScroll(){
      var scrollTop = $(window).scrollTop();
      if (scrollTop < 90) {
        $(".content").css('background','rgba(255,255,255,.8)');
        $("#news h2").css({
          'fontSize':'1rem',
          'marginTop':'-20px',
          'paddingBottom':'22px'
        });
        $("#news h2 i").css('display','block');
      } else {
        $(".content").css('background','#fff');
        $("#news h2").css({
          'fontSize':'1.75rem',
          'marginTop':'0',
          'paddingBottom':'0'
        });
        $("#news h2 i").css('display','none');
      }
    }
    $(window).on("load scroll", newsScroll);
    // instagram
    // $('#instagram').imagesLoaded(function(){
    //   $(".instagram").text("loading...");
    //   $.ajax({
    //     url: '/insta.php',
    //     dataType: 'json',
    //     error: function(jqXHR, textStatus, errorThrown) {
    //       $("#instagram .instagram").text(textStatus);
    //     },
    //     success: function(data) {
    //       var insert = '';
    //       for (var i = 0; i < data.length; i++) {
    //         // insert += '<div class="col-4 col-sm-3 col-md-2">';
    //           // 画像とリンク先
    //           // insert += '<a href="' + data[i]['link'] + '" target="_blank">';
    //           // insert += '<img src="' + data[i]['images']['low_resolution']['url'] + '" class="instagram-image img-fluid" />';
    //           // insert += '</a>';
    //         // insert += '</div>';
    //
    //         insert += '<a href="' + data[i]['link'] + '" target="_blank">';
    //         insert += '<div class="insta-img" style="background:url(' + data[i]['images']['low_resolution']['url'] + ')no-repeat center center;background-size:cover;width: 100%;padding-bottom: 100%;"></div>';
    //         insert += '</a>';
    //       };
    //       $('#instagram .insta-photos').append(insert);
    //       $("#instagram .instagram").text("");
    //     }
    //   });
    // });
    // 楽しみ方
    $('.customer-slide').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      infinite: true,
      pauseOnHover: false,
      centerPadding: '0',
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        }
      }]
    });
  }
  // products
  if ($('body').hasClass('products-detail')) {
    $('.slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      centerMode: true,
      autoplay: true,
      dots: true
    });
    $('.popup').magnificPopup({
      type: "image",
      gallery: {
        enabled:true
      },
      image: {
        titleSrc: 'title'
      }
    });
    // 幅広時 高さ
    $(window).on('load resize', function(){
      $('.item-detail').css('height','auto');
      $('.item-body-inner').css('paddingTop',0);
      if ($(window).width()>768){
        var detailHeight = $('.item-detail').height();
        var bodyHeight = $('.item-detail .item-body').height();
        var bodyHeightInner = $('.item-detail .item-body-inner').height();
        if (detailHeight < bodyHeight) {
          $('.item-detail').css('height',bodyHeight+'px');
        }else {
          var bodyPadding = (detailHeight - bodyHeightInner - 90)/2;
          $('.item-body-inner').css('paddingTop',bodyPadding+'px');
        }
      }
    });
    $('#variations .color select[name="opt2"]').attr('disabled');
  }
  // shopinfo
  if ($('body').hasClass('products-plon')) {
    $(window).on('load resize', function(){
      $('#case .case').each(function(){
        var imgWidth = $('img', this).width() + 'px';
        $('p', this).width(imgWidth);
      });
    });
  }
  // shopinfo
  if ($('body').hasClass('shopinfo')) {
    // スムーススクロール
    // $('a[href^="#"]').smoothScroll({offset: -50});
    // $('a[href^="#"]').on('click', function(){
    //   var speed = 500;
    //   var href= $(this).attr('href');
    //   var target = $(href == '#' || href =='' ? 'html' : href);
    //   var position = target.offset().top - 70;
    //   $('html, body').animate({scrollTop:position}, speed);
    //   return false;
    // });
  }
  // faq
  if ($('body').hasClass('faq')) {
    // スムーススクロール
    $('a[href^="#"]').smoothScroll({offset: -80});
    $('#bg').backstretch("/uploads/faq-bg.jpg");
  }
});


  if ($('body').hasClass('order')) {
    // var total;
    // var price = $('.item-price:first .num').text();
    // // var quantity = $('select:first').val();
    // price = price - 0.0;
    // console.log(price);

    // $('body').prepend(quantity);


    $('select').change(function() {
      var total_price = 0;
      var total_quantity = 0;
      $('select').each(function(){
        var quantity = parseInt($(this).val());
        total_quantity += quantity;
        var price = $(this).parents('.col-2').find('.item-price .num').text();
        price = parseFloat(price).toFixed(2);
        total_price += quantity*price;
      });
      // var quantity = $(this).val();
      // var price = $(this).parents('.col-2').find('.item-price .num').text();
      // price = parseFloat(price).toFixed(2);

      $('.order-total').empty().prepend(total_quantity + ' items<br>&euro;'+(total_price).toFixed(2));
    });
  }
